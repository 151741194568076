.div-for-slider {
  width: 60%;
  max-width: 300px;
  @media (min-width: 768px) {
    max-width: 360px;
    width: 80%;
  }
}

.slider {
  aspect-ratio: 9/16;
  overflow: hidden;
  box-shadow: 20.7px 20.7px 6.7px -5px rgba(0, 0, 0, 0.25),
    35.4px 35.4px 18.4px -5px rgba(0, 0, 0, 0.24),
    49.1px 49.1px 44.3px -5px rgba(0, 0, 0, 0.151),
    100px 100px 147px -5px rgba(0, 0, 0, 0.05);
  & video {
    object-fit: cover;
    display: block;
  }

  @media (min-width: 768px) {
    width: 300px;
    background: #545454;
  }
}

.awssld {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.awssld__content > video {
  width: 100%;
  object-fit: cover;
}
.banner-container {
  margin-top: 60px;
  width: 100%;
  height: auto;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 768px) {
    width: 80%;
    margin-top: 80px;
    min-height: calc(100vh - 80px);
    flex-direction: row;
    justify-content: space-around;
  }
}

.title-parallax {
  display: flex;
  justify-content: center;
}

.banner-text {
  width: 90%;
  color: #001829;
  @media (min-width: 768px) {
    width: 60%;
    margin-left: 30px;
  }
  & p {
    text-align: justify;
  }
}
