.hamburger {
  display: block;
  @media screen and (min-width: 992px) {
    display: none;
  }
}

.hamburger-react {
  z-index: 20;
  color: #00375f;
  margin-right: 5px;
}
.nav-links {
  margin-top: 7px;

  position: absolute;
  top: 0;
  right: -200px;
  background-color: rgba(41, 68, 105, 0.4);
  width: 100px;
  height: 250px;
  display: flex;
  row-gap: 10px;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 15px;
  padding-right: 30px;
  z-index: 10;
  transition: all 0.5s;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  & a {
    width: 100%;
    color: #fcfcfc;
  }
}
.open {
  right: 10px;
}
