.App {
  min-height: 100vh;
  /* padding: 10px; */
  // text-align: center;
  /* background-color: rgba(0, 0, 0, 0.7); */
  /* background-image: url('../public//image1.jpg') ; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.section-background {
  /* background-image: url('../public//image1.jpg') ; */
  padding: 40px;
  min-height: 50vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
section {
  /* background-image: url('../public//image1-white.jpg') ; */
  padding-bottom: 70px;
  padding-top: 30px;
  height: auto;
  min-height: 40vh;
  @media (min-width: 768px) {
    min-height: 80vh;
  }
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2,
h1 {
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  color: #8b003d;
  // color: #016b97;
  // color: #c84a27;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 576px) {
    font-size: 2rem;
  }

  @media (min-width: 768px) {
    font-size: 4rem;
  }
}

h3 {
  font-family: "Roboto", sans-serif;
  font-size: 1.1rem;
  color: #00375f;
  // color: #8fb552;
  // color: #0c339b;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}

h4 {
  font-family: "Roboto", sans-serif;
}

.app-container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    width: 80%;
  }
}

.title {
  margin-top: 40px;
  width: 100%;
  width: 90%;
  color: #001829;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  box-shadow: 20.7px 20.7px 6.7px -5px rgba(0, 0, 0, 0.25),
    35.4px 35.4px 18.4px -5px rgba(0, 0, 0, 0.24),
    49.1px 49.1px 44.3px -5px rgba(0, 0, 0, 0.151),
    100px 100px 147px -5px rgba(0, 0, 0, 0.05);

  & p {
    font-size: 14px;
    padding: 5px;
  }
  @media (min-width: 768px) {
    margin-top: 0;
    // margin-left: 30px;
    width: 80%;
    // margin : 80px ;

    & p {
      font-size: 16px;
      padding: 15px;
    }
  }
}

.title2 {
  color: #111;
  background: rgba(256, 256, 256, 0.6);
  min-height: 500px;
}
a {
  text-decoration: none;
  color: #00375f;
}
#CookiebotWidget
  #CookiebotWidget-buttons
  #CookiebotWidget-btn-change
  #CybotCookiebotDialog {
  background-color: #008b04 !important;
  border-color: #008b04 !important;
}
