.visual-container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & canvas {
    box-shadow: 20.7px 20.7px 6.7px -5px rgba(0, 0, 0, 0.25),
      35.4px 35.4px 18.4px -5px rgba(0, 0, 0, 0.24),
      49.1px 49.1px 44.3px -5px rgba(0, 0, 0, 0.151),
      100px 100px 147px -5px rgba(0, 0, 0, 0.05);
  }
}

.visual {
  height: 100%;
  width: 100%;
}
.invisible {
  width: 0;
  height: 0;
  overflow: hidden;
}

.canvas {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: visible;
}

.wall-mockup {
  width: 90%;
  aspect-ratio: 9/6;
  background-image: url("./layout3.webp");
  background-size: cover;
  margin: 0 auto;
  box-shadow: 20.7px 20.7px 6.7px -5px rgba(0, 0, 0, 0.25),
    35.4px 35.4px 18.4px -5px rgba(0, 0, 0, 0.24),
    49.1px 49.1px 44.3px -5px rgba(0, 0, 0, 0.151),
    100px 100px 147px -5px rgba(0, 0, 0, 0.05);
  @media screen and (min-width: 576px) {
    width: 70%;
  }
  @media screen and (min-width: 768px) {
    width: 50%;
  }
}

.inputs {
  margin: 65px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.label {
  cursor: pointer;
  border: 0;
  margin: 5px;
  background: rgba(0, 0, 0, 0);
  display: inline-block;
  font-size: 14px;
  @media screen and (min-width: 576px) {
    font-size: 18px;
  }
  font-weight: 500;
  color: #00375f;
  transition: all 0.5s ease-in-out;
}

.label:not(.disabled):hover {
  transform: scale(1.2);
  color: #8b003d;
}

.label.disabled {
  color: #777;
  cursor: not-allowed;
}

.visualizer-parallax {
  width: 85%;
  @media screen and (min-width: 768px) {
    width: 50%;
  }
}
