.gallery-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
}

.gallery-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gallery-imagelist {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
}
.MuiImageList-root {
  width: 95%;
  backdrop-filter: saturate(180%) blur(10px);
  @media (min-width: 768px) {
    width: 90%;
  }
}

.gallery-slider img {
  height: 80vh;
  max-height: 900px;
  min-width: auto;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 1;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
