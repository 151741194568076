.navbar {
  height: 0px;
  // width: 50%;
  display: none;
  justify-content: space-around;
  align-items: center;
  padding: 0;

  @media (min-width: 992px) {
    display: flex;
  }
}
