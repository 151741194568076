.header {
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 50;
  background-color: rgba(255, 255, 255, 0.8);
}
@media (min-width: 992px) {
  .header {
    justify-content: space-around;
    height: 80px;
  }
}

.logo {
  margin-left: 7px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}/*# sourceMappingURL=Header.css.map */