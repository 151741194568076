.navbar {
  height: 0px;
  display: none;
  justify-content: space-around;
  align-items: center;
  padding: 0;
}
@media (min-width: 992px) {
  .navbar {
    display: flex;
  }
}/*# sourceMappingURL=Navbar.css.map */