.slider2 {
  position: absolute;
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  animation: scaleIn 0.5s forwards;
  z-index: 1000;
}
@media (max-width: 768px) {
  .slider2 {
    width: 100%;
    height: 70%;
  }
}
@media (max-width: 500px) {
  .slider2 {
    width: 100%;
    height: 60%;
  }
}

.media-slider video {
  -o-object-fit: cover;
     object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  display: block;
  touch-action: manipulation;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: grey;
  background: transparent;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1001;
}

.media-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.media-content {
  max-width: 80%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}/*# sourceMappingURL=projectSlider.css.map */