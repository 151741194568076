.price-section {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.price-container {
  width: 100%;
  min-height: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & ul li {
    list-style-type: disc;
    text-align: left;
  }
}

.ceny {
  height: auto;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.15);
  // background-image: url('./image27.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #001829;
  display: flex;
  flex-direction: column;
  align-items: center;
  & div {
    width: 80%;
  }
  & p {
    text-align: left;
  }
}
