.technika-container {
  // padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  row-gap: 2rem;
}

.icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.icon {
  width: 90%;
  margin: 10px;
  box-shadow: 20.7px 20.7px 6.7px -5px rgba(0, 0, 0, 0.25),
    35.4px 35.4px 18.4px -5px rgba(0, 0, 0, 0.24),
    49.1px 49.1px 44.3px -5px rgba(0, 0, 0, 0.151),
    100px 100px 147px -5px rgba(0, 0, 0, 0.05);
  @media (min-width: 576px) {
    width: 350px;
  }
}

.technika,
.surfaces {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  min-height: 40vh;
  width: 80%;
  padding: 30px 0;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.15);
  box-shadow: 20.7px 20.7px 6.7px -5px rgba(0, 0, 0, 0.25),
    35.4px 35.4px 18.4px -5px rgba(0, 0, 0, 0.24),
    49.1px 49.1px 44.3px -5px rgba(0, 0, 0, 0.151),
    100px 100px 147px -5px rgba(0, 0, 0, 0.05);
  & p {
    text-align: justify;
    width: 90%;
    color: #001829;
    @media (min-width: 768px) {
      width: 80%;
    }
  }
}

.surfaces-gallery {
  display: flex;
  flex-direction: row;
  color: #001829;
  flex-wrap: wrap;
  justify-content: center;
  & img {
    width: 85%;
    height: auto;
    border-radius: 20px;
    margin: 10px;
    transition: 0.7s;
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      box-shadow: 20.7px 20.7px 6.7px -5px rgba(0, 0, 0, 0.25),
        35.4px 35.4px 18.4px -5px rgba(0, 0, 0, 0.24),
        49.1px 49.1px 44.3px -5px rgba(0, 0, 0, 0.151),
        100px 100px 147px -5px rgba(0, 0, 0, 0.05);
    }
  }
}
.surface-section {
  padding: 15px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  row-gap: 1rem;
  margin: 10px;
  background-color: #fafafa;
  box-shadow: 20.7px 20.7px 6.7px -5px rgba(0, 0, 0, 0.25),
    35.4px 35.4px 18.4px -5px rgba(0, 0, 0, 0.24),
    49.1px 49.1px 44.3px -5px rgba(0, 0, 0, 0.151),
    100px 100px 147px -5px rgba(0, 0, 0, 0.05);
  @media (min-width: 576px) {
    width: 300px;
  }
  & p {
    color: #001829;
  }
  & h4 {
    color: #8b003d;
    text-transform: uppercase;
  }
}
